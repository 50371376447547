@import "../../assets/Styles/variables.module";

$headerCellWidth: 250px;
$headerCellMobileWidth: 150px;
$cellWidth: 100px;

$legendColor1: white;
$legendColor2: #DFEDFF;
$legendColor3: #DCE9D5;
$legendColor4: #EDCDCD;

.loadPlan {
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    margin: 16px 0;
  }
}

.legend {
  display: flex;
  right: 16px;
}

.legendItem1 {
  background-color: $legendColor1;
  padding: 8px 16px;
  border: 1px solid $lightBlue;
  border-radius: 10px 0px 0px 10px;
}

.legendItem2 {
  background-color: $legendColor2;
  padding: 8px 16px;
}

.legendItem3 {
  background-color: $legendColor3;
  padding: 8px 16px;
}

.legendItem4 {
  background-color: $legendColor4;
  padding: 8px 16px;
  border-radius: 0px 10px 10px 0px;
}

.loadPlan table {
  margin-bottom: 20px;
  table-layout: fixed;
  min-width: 250px;
}

.loadPlan .cell {
  border-bottom: none;
  padding-left: 2rem;
  padding-right: 2rem;
}

.loadPlan thead .cell,
.loadPlan tbody .cell:first-child {
  position: sticky;
  top: 0;
  left: 73px;

  @media (max-width: 600px) {
    left: 0;
  }
}

.loadPlan thead {
  .cell {
    background-color: $lightBlue;
    border-bottom: none;
    text-align: center;
    padding: 8px;
    width: $cellWidth;
  }

  .cell:first-child {
    text-align: start;
    font-weight: bold;
    width: $headerCellWidth;
    z-index: 2;

    @media (max-width: 600px) {
      width: $headerCellMobileWidth;
    }
  }
}

.loadPlan tbody {
  .cell {
    padding: 0;
    min-width: $cellWidth;
  } 

  .cell:first-child {
    padding-left: 2rem;
    background-color: #fafafa;
    width: $headerCellWidth;

    @media (max-width: 600px) {
      padding-left: 1rem;
      width: $headerCellMobileWidth;
    }
  }

  .cell .innerCell {
    border: 1px solid $lightBlue;
    border-radius: 10px;
    margin: 6px;
    padding: 12px;
    text-align: center;
    border-collapse: separate;
  }
}

.loaderDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.loaderText {
  margin-top: 32px;
}

.loader {
  border: 16px solid $lightBlue;
  border-top: 16px solid $mainColor;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

:export {
  legendColor1: $legendColor1;
  legendColor2: $legendColor2;
  legendColor3: $legendColor3;
  legendColor4: $legendColor4;
}