@import '../../../../assets/Styles/variables.module';

.totalSpan, .allocationSpan{
    color: $mainColor;
}

.totalSpan{
    font-size: 1.5rem;
}

.allocationSpan{
    font-size: 3rem;
}

.serviceIcon {
    margin-bottom: 12%;
    font-size: 2.8rem;
}

.totalResource {
    color: $mainColor;
    margin-left: auto;
    margin-right: 7%;
    margin-top: -10%;
}

.cardStyle {
    flex-grow: 1;
    width: 100%;
    border-style: solid;
    margin-bottom: 1rem;
}

.cardItem {
    flex: 1;
}

.card-service-header {
    font-size: 2.2 rem;
}