@import "../../../../assets/Styles/variables.module";

.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    /* height: 50px; */
}

.divTitle {
    background-color: #dddddd;
    height: auto;
    text-align: center;
    margin: auto;
    padding: 10px;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.divitem {
    display: flex;
    justify-content: space-between;
    /* align-items: initial; */
    padding: 10px;

    .avatar {
        background-color: $lightBlue;
        color: black;
        margin-right: 4px;
        font-size: 80%;
        width: 28px;
        height: 28px;
    }
}

.divitem p {
    padding-top: 16px;
    font-size: 16px;
    margin-right: 24px;
}

.itemDetails {
    padding-top: '50px';
}

.iconEdit {
    width: 30px;
    height: 30px;
}