@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/resource-timeline/main.css";
@import "~@fullcalendar/timeline/main.css";

// personal styling SCSS
@import "../../assets/Styles/variables.module";

/**
 * Fix to the wide last column in timeline full-calendar view
 * @see https://github.com/fullcalendar/fullcalendar/issues/6358
 */
.fc col {
  min-width: auto !important;
}

.fc td {
  border-width: 2px;
  border-color: $lightBlue;
}

.fc-unthemed thead,
tbody,
.fc-divider {
  border-color: $lightBlue !important;
}

@media (max-width: 600px) {
  .fc-resourceTimeline-view {
    width: 350px;
  }
}

.fc-timeline-event.fc-not-start:before,
.fc-timeline-event.fc-not-end:after {
  margin: 0 4px;
  opacity: 1;
  border: 8px solid white;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.fc-widget-header {
  border: none !important;
  background-color: $lightBlue;

  table {
    tbody {
      tr:first-child {
        th {
          padding: 10px;
          border: none;

          .fc-cell-content {
            text-align: center !important;
            span {
              position: relative;
              display: block;
            }
          }
        }
      }
      tr:nth-child(2) {
        .fc-cell-text {
          font-size: 0px;
          &:before {
            font-size: 14px;
            visibility: visible;
          }
        }
        th {
          text-align: center !important;
          background-color: white;
        }
        th:nth-child(odd) {
          .fc-cell-text {
            &:before {
              content: "Morning";
            }
          }
        }
        th:nth-child(even) {
          .fc-cell-text {
            &:before {
              content: "Afternoon";
            }
          }
        }
      }
    }
  }
}

// with a resource role this style is missed
.fc-event {
  cursor: pointer;
  border-radius: 50px;
}

.fc-chrono {
  border-left: 1px;

  .fc-cell-content {
    border-left: 2px solid $lightBlue;
    border-bottom: 2px solid $lightBlue;
  }

  .fc-widget-header:last-child .fc-cell-content {
    border-right: 2px solid $lightBlue;
  }
}

.fc-head {
  margin-bottom: 1.5em;
  position: sticky;
  top: 4.1rem;
  z-index: 100;
}

.fc-head .fc-resource-area {
  visibility: hidden;
  border: none;
}

.fc-col-resizer {
  background-color: $lightBlue !important;
}

.fc-widget-content {
  [data-resource-id] {
    .fc-icon,
    .fc-expander-space {
      display: none;
    }
  }

  .MuiAvatar-circle {
    margin-right: 12px;
  }

  .fc-divider {
    font-weight: bold;
  }

  .fc-highlight {
    background-color: $mainColor;
  }
}

.fc-ltr .fc-timeline-event {
  align-items: center;
  padding: 8px 1px;

  .fc-title {
    font-weight: bold;

    svg {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: #fff;
      color: #ff0000;
      padding: 2px;
    }
  }
}

.fc-resource-area,
.fc-time-area {
  .fc-divider {
    background-color: $lightBlue;
  }

  .fc-scroller-canvas {
    overflow: hidden !important;
  }

  @media (max-width: 600px) {
    .fc-cell-content {
      padding-left: 0;
    }
  }
}

.fc-timeline {
  .fc-divider {
    width: 2px;
  }

  @media (max-width: 600px) {
    .fc-cell-text {
      padding-left: 0;
    }
  }
}

.fc-time-area .fc-event-container {
  top: 4px;
  // So the tooltip will be disaplyed above it
  z-index: inherit;
}

.myEvents {
  border-radius: 50px;
}

.stripes {
  background: repeating-linear-gradient(
    -55deg,
    #222,
    #222 10px,
    #333 10px,
    #333 20px
  );
  &.holiday {
    background: repeating-linear-gradient(
      -55deg,
      #03116b,
      #03116b 10px,
      #0525f6 10px,
      #0525f6 20px
    );
  }
}

.free_resource {
  border-left: 1.5mm solid #00c9ab;
  transition: 0.2s linear;
}

.not_free_resource {
  border-left: 1.5mm solid #b94860;
  transition: 0.2s linear;
}

.div_resource_content::after {
  display: inline-table;
}

.addEventAction {
  border-right-style: 4px solid #676e7a;
  border-left-style: 4px solid #676e7a;
}

.external-day-off {
  color: #f5b05d;
  pointer-events: none;
}

// Event with default rendering tooltip
.tooltipCustom {
  position: relative;
  display: inline-block;
}

.tooltipCustom .tooltipCustomtext {
  visibility: hidden;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  // parent (background event or standard event) has already a defined z-index.
  z-index: 5;
  top: 100%;
  transition: visibility 0.4s linear, opacity 0.4s linear;
  opacity: 0;
}

.tooltipCustom:hover .tooltipCustomtext {
  visibility: visible;
  opacity: 1;
}
