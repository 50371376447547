.toggleButton {
    width: 48px;
    height: 40px;
    color: black !important;
    border: 0.2rem !important;
    border-radius: 0.5rem !important;
}

@media (max-width: 600px) {
    .toggleButton {
        width: 36px;
        height: 32px;
    }
}