.custom-recurrence__label {
  font-size: 0.875rem;
}

.custom-recurrence-frequency__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .interval,
  .frequency_type {
    margin: 0.675rem !important;
  }
}

.custom-recurrence-weekday__container,
.custom-recurrence-end-limit__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.5rem 0rem;
  .week_span {
    width: 2.3rem;
    font-size: 0.87rem;
    margin: 0.6rem 0.625rem 0rem 0rem;
    border-radius: 1.57rem !important;
    border: 0.2rem !important;
  }
  .end-limit_option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .radio-content {
        margin: 0.675rem !important;
    }
  }
}
