@import "../../../assets/Styles/variables.module";

.div {
    display: flex;
    align-items: flex-end;

    @media (min-width: 600px) {
        margin-left: 3px;
    }
    
    .avatar {
        background-color: $lightBlue;
        color: black;
        margin-right: 4px;
        font-size: 80%;
        width: 28px;
        height: 28px;
    }

    @media (max-width: 600px) {
        .avatar {
            width: 0px;
        }
    }
}
