@import "../../../../assets/Styles/variables.module";

.service {
    .divServiceName {
        height: 40px;
        text-align: center;
        margin: auto;
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 10px;
    }
    
    .avatar {
        background-color: $lightBlue;
        color: black;
        margin-left: 7px;
        margin-top: 2px;
        font-size: 15px;
        width: 40px;
        height: 40px;
    }
}
