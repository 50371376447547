$mainColor: #2F184C;
$secondaryColor: #B94860;
$loginButtonColor: #2491f7;
$lightBlue: #e3e7ef;
$lightGrey: #C3C3C3;

:export {
  mainColor: $mainColor;
  secondaryColor: $secondaryColor;
  lightBlue: $lightBlue;
  lightGrey: $lightGrey;
}