@import "../../assets/Styles/variables.module";

@media (max-width: 600px) {
  .calendarDiv {
    min-width: 950px;
  }
}

.outsideactions {
  display: flex;
  justify-content: space-between;
}

:global(.maintabs) {
  margin-left: 80px;
}

@media (max-width: 600px) {
  :global(.maintabs) {
    margin-left: 0;
  }
}

.membersnumber {
  color: $mainColor;
  margin: 0 8px;
}

@media (max-width: 600px) {
  .membersnumber {
    display: none;
  }
}

.appbar {
  color: white;
  flex-grow: 1;

  @media (max-width: 600px) {
    .inner {
      flex-direction: column !important;
      margin-left: 0px !important;

      .outsideactions {
        flex-direction: column !important;

        button {
          margin-bottom: 8px;
        }
      }

      .toggleButton {
        margin-left: 0 !important;
      }
    }
  }
}

// above Full calendar
.maintabsavatar {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

@media (max-width: 600px) {
  .maintabsavatar {
    margin-top: 64px;
    flex-direction: column;
    padding-bottom: 0;

    div {
      margin-bottom: 8px;
    }
  }
}

.avatarsDiv {
  // margin-top: 6px;
  margin-left: 0%;

  .avatarStyle {
    background-color: $lightBlue;
    color: black;
    margin-left: 7px;
    margin-top: 2px;
    font-size: 15px;
    width: 40px;
    height: 40px;
  }
}

.serviceDiv {
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 600px) {
    margin-left: 0;
  }

  .activeTab {
    width: 90px;
    border-color: $lightBlue;
    background-color: $mainColor;
    color: white;
    border-radius: 4px;
  }

  @media (max-width: 600px) {
    .activeTab {
      width: 72px;
    }
  }

  .inactiveTab {
    width: 90px;
    border: 2px solid $lightBlue !important;
    background-color: white;
    color: $mainColor;
    border-radius: 4px;
  }

  @media (max-width: 600px) {
    .inactiveTab {
      width: 72px;
    }
  }
}

.navigationDiv {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  // hidden date picker take space

  @media (max-width: 600px) {
    margin-left: 0;
    margin-right: 0;
  }

  .dateRange {
    background-color: white;
    color: $mainColor;
    border: 2px solid $lightBlue;
  }

  .navigationButton {
    background-color: white;
    border: 2px solid $lightBlue;
  }

  .navigationIcon {
    color: $mainColor;
  }
}

$affectConfirmedColor: $mainColor;
$affectNotConfirmedColor: #bd6904;
$affectConfirmedBidColor: #745492;
$affectNotConfirmedBidColor: #DE914D;

:export {
  mainColor: $mainColor;
  affectConfirmedColor: $affectConfirmedColor;
  affectNotConfirmedColor: $affectNotConfirmedColor;
  affectConfirmedBidColor: $affectConfirmedBidColor;
  affectNotConfirmedBidColor: $affectNotConfirmedBidColor;
}