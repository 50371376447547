@import "../../../../assets/Styles/variables.module";

.select {
    display: flex;
    align-items: flex-end;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }

    .input {
        padding: 10px;
    }

    .formControl {
        margin: 0 24px 0 8px;
        min-width: 18%;
    }

    .chip {
        margin: 8px;
        background-color: $mainColor;
        color: white;

        @media (max-width: 600px) {
            max-width: 90vw;
        }

        svg {
            color: white;
        }
    }
}
