.bigDiv {
    border-radius: 25px;
    padding: 15px;
    margin-top: 40px;
    margin-left: 20px;
}

.topleft {
  position: absolute;
  top: 60px;
  margin-left: 20px;
  font-size: 18px;
  border-radius: 25px;
  background: #ffcc00;
  padding: 10px;
  z-index: +2;
  display: inline-table;
}

