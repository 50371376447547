@media (max-width: 600px) {
  .dialog {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    margin: 0 !important;
    background-color: blue;
    //max-width: 100% !important;
  }
}

// enable scroll bar in mobile view
@media (max-width: 600px) {
  :global(.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody) {
    height: 100vh !important;
    max-width: 100% !important;

    :global(.MuiDialogContent-root) {
      padding-left: 8px !important;
      padding-right: 0px !important;
    }
  }
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
}

.divitem {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

:global(.MuiInputBase-input) {
  font-size: 0.8rem;
}


.timePickerInput {
  width: 200px !important;
}

@media (max-width: 600px) {
  .timePickerInput {
    width: 100px !important;
  }
}

