.action {
  background-color: inherit;
  color: #aecbfa;
  border: none;
  :hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
}

// Custom snackbar content styles
.customSnackRoot {
  @media (min-width: 600px) {
    min-width: 288px !important;
  }
}

.customSnackCard {
  background-color: inherit;
  width: 100%;
}

.customSnackActionRoot {
  padding: 8px 8px 8px 16px;
  justify-content: space-between;
  color: #fff;
  background-color: #313131;
}

.customSnackIcons {
  margin-left: auto;
}

.customSnackExpand {
  padding: 8px 8px;
  transform: rotate(0deg);
  transition: all .2s;
  color: #fff !important;
}

.customSnackCollapse {
  padding: 16;
}

.customLineItem {
  width: inherit;
  word-wrap: break-word;
}

.customSnackButton {
  padding: 0;
  text-transform: none;
}
