.divMainContain {
  margin-top: 35px;
  margin-left: 80px;
  margin-bottom: 20px;
  margin-right: 10px;
  background-color: beige;
  box-shadow: 5px 10px 18px #888888;
  border-radius: 25px;
}

.root1 {
  display: flex;
}

.content {
  flex-grow: 1;
  padding: 24px;
}

.hashLoader {
  top: 25%;
  right: 40%;
  position: absolute;
}

@media (max-width: 600px) {
  .content {
    padding: 0;
    overflow-y: hidden;
  }
}
